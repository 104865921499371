.App {
  text-align: center;
  margin: 2rem 2rem 2rem 2rem;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.parent-div {
  position: relative; 
  width: 100%; 
  height: 0; 
  padding-top: 56.2500%;
  padding-bottom: 0; 
  box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
  margin-top: 1.6em; 
  margin-bottom: 0.9em; 
  overflow: hidden;
  border-radius: 8px; 
  will-change: transform;
}

.lazy-div{
  position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;
}